<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('settings.changeEmail') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('settings.changeEmailExplanation1') }}
					</p>
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('settings.changeEmailExplanation2') }}
					</p>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-form ref="form" v-model="valid">
						<v-text-field
							:value="currentEmail"
							:label="$t('auth.currentEmail')"
							outlined
							rounded
							prepend-inner-icon="mdi-account-circle"
							hide-details="auto"
							class="pb-3"
							readonly
							style="max-width: 400px"
						/>
						<v-text-field
							v-model="email"
							:label="$t('auth.newEmail')"
							:hint="$t('auth.emailHelp')"
							:rules="rules.email"
							required
							outlined
							rounded
							prepend-inner-icon="mdi-email"
							hide-details="auto"
							class="pb-3"
							style="max-width: 400px"
						/>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn x-large rounded class="px-4" color="primary" @click="callChangeEmail()">
				{{ $t('settings.changeEmail') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'SettingsChangeEmailForm',
	props: {
		currentEmail: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			valid: false,
			email: '',
			rules: {
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength }),
				],
			},
		}
	},
	computed: {
		emailMaxLength() {
			return 255
		},
	},
	methods: {
		callChangeEmail() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeEmail(this.email)
		},
		...mapActions('settings', ['changeEmail']),
	},
}
</script>
